// assets
import {
  AudioSquare,
  CalendarEdit,
  Dash,
  DeviceMessage,
  DocumentText1,
  Fatrows,
  HomeTrendDown,
  Message,
  MessageAdd1,
  MoneyArchive,
  MoneyRecive,
  NotificationFavorite,
  PresentionChart,
  Profile2User,
  Send2,
  Solana,
  Story,
  UserSquare,
  UserTick
} from "iconsax-react";

// third-party
import { FormattedMessage } from "react-intl";

// icons
const icons = {
  widgets: Story,
  statistics: Story,
  data: Fatrows,
  chart: PresentionChart,
  message: Message,
  addMsg: MessageAdd1,
  sent: Send2,
  schedule: CalendarEdit,
  alert: NotificationFavorite,
  audio: AudioSquare,
  dashboard: Dash,
  user: Profile2User,
  reports: DocumentText1,
  billing: MoneyArchive,
  senderid: UserTick,
  setups: MoneyRecive,
  msetups: DeviceMessage,
  users: UserSquare,
  hmsg: HomeTrendDown,
  bundle: Solana
};

// ==============================|| MENU ITEMS - WIDGETS ||============================== //

const admin = {
  id: "admin-menu",
  title: <FormattedMessage id="Admin Menu" />,
  icon: icons.message,
  type: "group",
  children: [
    {
      id: "admin-dashboard",
      title: <FormattedMessage id="Dashboard" />,
      type: "item",
      url: "/admin/dashboard",
      icon: icons.dashboard
    },
    {
      id: "clients",
      title: <FormattedMessage id="Clients" />,
      type: "collapse",
      icon: icons.user,
      children: [
        {
          id: "client-details",
          title: (
            <>
              <FormattedMessage id="Clients List " />
            </>
          ),
          type: "item",
          url: "/admin/clients/client-list"
        }
      ]
    },
    {
      id: "approve-senderid",
      title: <FormattedMessage id=" Sender IDs" />,
      type: "collapse",
      icon: icons.senderid,
      children: [
        {
          id: "all-senderids",
          title: (
            <>
              <FormattedMessage id="All Sender IDs" />
            </>
          ),
          type: "item",
          url: "/admin/senderids"
        },
        {
          id: "all-pending-senderids",
          title: (
            <>
              <FormattedMessage id="Pending Sender IDs" />
            </>
          ),
          type: "item",
          url: "/admin/senderids/pending"
        },
        {
          id: "all-banned-senderids",
          title: (
            <>
              <FormattedMessage id="Banned Sender IDs" />
            </>
          ),
          type: "item",
          url: "/admin/senderids/banned"
        }
      ]
    },
    {
      id: "bundles-credits",
      title: <FormattedMessage id="Bundles & Credits" />,
      type: "collapse",
      icon: icons.bundle,
      children: [
        {
          id: "bundles",
          title: (
            <>
              <FormattedMessage id="Bundles" />
            </>
          ),
          type: "item",
          url: "/admin/bundles"
        }
      ]
    },
    // {
    //   id: "billing",
    //   title: <FormattedMessage id="Billing" />,
    //   type: "collapse",
    //   icon: icons.billing,
    //   children: [
    //     // {
    //     //   id: " billing-orders",
    //     //   title: (
    //     //     <>
    //     //       <FormattedMessage id="Orders/Invoices" />
    //     //     </>
    //     //   ),
    //     //   type: "item",
    //     //   url: "/admin/billing/bill-orders/:id"
    //     // },
    //     {
    //       id: "client-details",
    //       title: (
    //         <>
    //           <FormattedMessage id="Billing Run Details" />
    //         </>
    //       ),
    //       type: "item",
    //       url: "/admin/billing"
    //     },
    //     {
    //       id: "bill-summary",
    //       title: (
    //         <>
    //           <FormattedMessage id="Bill Summary" />
    //         </>
    //       ),
    //       type: "item",
    //       url: "/admin/billing/bill-summary"
    //     },

    //     {
    //       id: " billing-payments",
    //       title: (
    //         <>
    //           <FormattedMessage id="Payments" />
    //         </>
    //       ),
    //       type: "item",
    //       url: "/admin/billing/payments"
    //     },
    //     {
    //       id: " bill-clients",
    //       title: (
    //         <>
    //           <FormattedMessage id="Bill Clients" />
    //         </>
    //       ),
    //       type: "item",
    //       url: "/admin/billing/bill-clients"
    //     },
    //     {
    //       id: "billing-providers",
    //       title: (
    //         <>
    //           <FormattedMessage id="Provider Bills" />
    //         </>
    //       ),
    //       type: "item",
    //       url: "/admin/billing/billing-providers"
    //     }
    //   ]
    // },
    // {
    //   id: "reports",
    //   title: <FormattedMessage id="Reports" />,
    //   type: "collapse",
    //   icon: icons.reports,
    //   children: [
    //     {
    //       id: "filter-messages",
    //       title: (
    //         <>
    //           <FormattedMessage id="Filter Messages" />
    //         </>
    //       ),
    //       type: "item",
    //       url: "/admin/messages/filter"
    //     },

    //     {
    //       id: " filter-reports",
    //       title: (
    //         <>
    //           <FormattedMessage id="FIlter Reports" />
    //         </>
    //       ),
    //       type: "item",
    //       url: "/admin/messages/reports/filter"
    //     }
    //   ]
    // },

    // {
    //   id: "smpp-request",
    //   title: <FormattedMessage id="SMPP Request " />,
    //   type: "collapse",
    //   icon: icons.message,
    //   children: [
    //     // {
    //     //   id: "pending-smpp-request",
    //     //   title: (
    //     //     <>
    //     //       <FormattedMessage id="Pending Request " />
    //     //     </>
    //     //   ),
    //     //   type: "item",
    //     //   url: "/admin/smpps/pending"
    //     // },

    //     {
    //       id: "all-smpps",
    //       title: (
    //         <>
    //           <FormattedMessage id="All SMPP Request" />
    //         </>
    //       ),
    //       type: "item",
    //       url: "/admin/smpps"
    //     }
    //   ]
    // },
    // {
    //   id: "home-items",
    //   title: <FormattedMessage id="Homepage Items" />,
    //   type: "collapse",
    //   icon: icons.hmsg,
    //   children: [
    //     {
    //       id: "all-msgs",
    //       title: (
    //         <>
    //           <FormattedMessage id="Messages List" />
    //         </>
    //       ),
    //       type: "item",
    //       url: "/admin/homepage/messages"
    //     }
    //   ]
    // },
    // {
    //   id: "wid",
    //   title: <FormattedMessage id="Withdrawals" />,
    //   type: "collapse",
    //   icon: icons.hmsg,
    //   children: [
    //     {
    //       id: "all-msgs",
    //       title: (
    //         <>
    //           <FormattedMessage id="All Withdrawal Request" />
    //         </>
    //       ),
    //       type: "item",
    //       url: "/admin/referral"
    //     }
    //   ]
    // },

    {
      id: "admin-users",
      title: <FormattedMessage id="Admin Users" />,
      type: "item",
      url: "/admin/users",
      icon: icons.users
    },
    // {
    //   id: "messaging-setups",
    //   title: <FormattedMessage id="Messaging Setups " />,
    //   type: "collapse",
    //   icon: icons.msetups,
    //   children: [
    //     {
    //       id: "destintions",
    //       title: (
    //         <>
    //           <FormattedMessage id="Destinations" />
    //         </>
    //       ),
    //       type: "item",
    //       url: "/admin/destinations"
    //     },
    //     {
    //       id: "rate-packages",
    //       title: (
    //         <>
    //           <FormattedMessage id="Rate Packages " />
    //         </>
    //       ),
    //       type: "item",
    //       url: "/admin/rate-packages"
    //     },
    //     {
    //       id: "rate-plans",
    //       title: (
    //         <>
    //           <FormattedMessage id="Rate Plans " />
    //         </>
    //       ),
    //       type: "item",
    //       url: "/admin/rate-plans"
    //     },
    //     {
    //       id: "services",
    //       title: (
    //         <>
    //           <FormattedMessage id="Services " />
    //         </>
    //       ),
    //       type: "item",
    //       url: "/admin/services"
    //     },
    //     {
    //       id: "providers",
    //       title: (
    //         <>
    //           <FormattedMessage id="Providers " />
    //         </>
    //       ),
    //       type: "item",
    //       url: "/admin/providers"
    //     }
    //   ]
    // },
    {
      id: "billing-setups",
      title: <FormattedMessage id="Billing Setups " />,
      type: "collapse",
      icon: icons.setups,
      children: [
        {
          id: "billing-destinations",
          title: (
            <>
              <FormattedMessage id="Destinations " />
            </>
          ),
          type: "item",
          url: "/admin/billing/destinations"
        }
      ]
    }
  ]
};

export default admin;
