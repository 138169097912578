import * as Yup from "yup";

import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  Stack,
  TextField
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { filter, get, map } from "lodash";

import { Add } from "iconsax-react";
import PropTypes from "prop-types";
import { createInvoiceList } from "store/reducers/setups";
import { dispatch } from "store";
import { getClientSubClients } from "store/reducers/users";
import { openSnackbar } from "store/reducers/snackbar";
import { useNavigate } from "react-router";
import { useSelector } from "store";
import useUserAuthInfo from "hooks/useAuthInfo";

export default function PurchaseBundle({ title }) {
  const { token, clientid } = useUserAuthInfo();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getClientSubClients(token, clientid));
  }, [token, clientid]);

  const clientSubClients = useSelector((state) => state.usersmanagement.clientSubClients);

  const list = get(clientSubClients, "content", []);

  const clients = [
    { value: clientid, label: "Self" }, // Default Self option
    ...map(filter(list, "isverified"), (item) => ({ value: item.id, label: item.name }))
  ];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box sx={{ display: "inline-block" }}>
        <Button variant="contained" color="secondary" onClick={handleClickOpen} startIcon={<Add />}>
          {title}
        </Button>
      </Box>
      <Dialog open={open} onClose={handleClose} maxWidth="xs">
        <DialogTitle> Add Prepaid Credit</DialogTitle>
        <Formik
          initialValues={{
            amount: 1,
            benefactorid: clientid,
            description: "",
            invoicetype: "TOPUP"
          }}
          validationSchema={Yup.object().shape({
            amount: Yup.number().min(1, "Amount must be greater than 0"),
            description: Yup.string()
          })}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);

            createInvoiceList(token, clientid, values)
              .then((res) => {
                dispatch(
                  openSnackbar({
                    open: true,
                    message: "Invoice Generated successfully!",
                    variant: "alert",
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                    alert: {
                      color: "success"
                    },
                    close: true
                  })
                );
                navigate(`credits/invoices/view/${res.data[0].id}`);
              })
              .catch((err) => {
                console.log(err);
                setSubmitting(false);
              })
              .finally(() => {
                handleClose();
                setSubmitting(true);
              });
          }}
        >
          {({ values, touched, errors, getFieldProps, handleChange, isSubmitting, setFieldValue }) => (
            <Form>
              <DialogContent>
                {isSubmitting ? ( // Render CircularProgress if loading state is true
                  <Stack direction="row" justifyContent="center" alignItems="center">
                    <CircularProgress color="secondary" />
                  </Stack>
                ) : (
                  <React.Fragment>
                    {/* <DialogContentText>Please enter the amount you would like to purchase.</DialogContentText> */}
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Stack spacing={1}>
                          <InputLabel htmlFor="amount">Amount</InputLabel>

                          <TextField
                            autoFocus
                            margin="dense"
                            id="amount"
                            type="number"
                            min="1"
                            name="amount"
                            value={values.amount}
                            onChange={handleChange("amount")}
                            {...getFieldProps("amount")}
                            fullWidth
                            InputProps={{
                              startAdornment: "GHS"
                            }}
                            error={values.amount < 1}
                            helperText={values.amount < 1 ? "Amount must be greater than 0" : ""}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Stack spacing={1}>
                          <InputLabel htmlFor="description">Description</InputLabel>
                          <TextField
                            multiline
                            rows={4}
                            placeholder="Enter a description for this purchase"
                            autoFocus
                            margin="dense"
                            id="description"
                            name="description"
                            {...getFieldProps("description")}
                            fullWidth
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Stack spacing={1}>
                          <InputLabel htmlFor="users-name">Topping Up For?</InputLabel>

                          <Autocomplete
                            disableClearable
                            selectOnFocus
                            value={clients.find((client) => client.value === values.benefactorid) || clients[0]}
                            clearOnBlur
                            autoHighlight
                            handleHomeEndKeys
                            id="benefactorid"
                            name="benefactorid"
                            options={clients}
                            getOptionLabel={(option) => option.label}
                            onChange={(event, newValue) => {
                              if (newValue.value === clientid) {
                                setFieldValue("benefactorid", clientid); // Set clientid to the default clientid value
                              } else {
                                setFieldValue("benefactorid", newValue.value); // Set clientid to the selected client's id
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={touched.benefactorid && Boolean(errors.benefactorid)}
                                helperText={touched.benefactorid && errors.benefactorid && errors.benefactorid}
                                {...params}
                              />
                            )}
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} disabled={isSubmitting}>
                  Cancel
                </Button>
                <Button type="submit" disabled={isSubmitting} variant="contained" color="primary">
                  {isSubmitting ? "Purchasing..." : "Purchase"}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}

PurchaseBundle.propTypes = {
  title: PropTypes.string
};
