// project-imports
import axios from "utils/axios";
import { createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";

// third-party

const initialState = {
  error: null,
  destinations: [],
  destination: null,
  bundles: [],
  balance: [],
  selectedClientBundle: null,
  clientBundles: [],
  bundleid: null,
  clientBundle: null,
  activeBundles: [],
  bundle: null,
  invoices: [],
  invoice: null,
  invoiceStatus: null,
  shareCash: [],
  cancel: null,
  providers: [],
  services: [],
  ratePlans: [],
  isLoading: false
};

// ==============================|| SLICE - setups ||============================== //

const setups = createSlice({
  name: "setups",
  initialState,
  reducers: {
    // has Error
    hasError(state, action) {
      state.error = action.payload;
    },

    // isLoading
    isLoading(state, action) {
      state.isLoading = action.payload;
    },

    // get destination list
    getDestinations(state, action) {
      const data = get(action.payload, "data", {});
      state.destinations = data;
    },

    // !* INVOICES *! //
    setInvoicesSuccess(state, action) {
      const invoices = get(action.payload, "data");
      state.invoices = invoices;
    },

    setInvoiceSuccess(state, action) {
      const invoice = get(action.payload, "data");
      state.invoice = invoice;
    },
    setInvoiceStatus(state, action) {
      state.invoiceStatus = action.payload;
    },

    createInvoicesSuccess(state, action) {
      state.invoices.push(action.payload);
    },

    cancelInvoicesSuccess(state, action) {
      state.cancel.push(action.payload);
    },

    getDestinationSuccess(state, action) {
      state.destination = action.payload;
    },
    getBalanceSuccess(state, action) {
      const balance = get(action.payload, "data", []);

      state.balance = balance;
    },

    // add new destination
    addDestination(state, action) {
      state.destinations.push(action.payload);
    },

    // delete destination
    deleteDestination(state, action) {
      state.destinations = state.destinations.filter((item) => item.id !== action.payload);
    },

    // update destination
    updateDestination(state, action) {
      const index = state.destinations.findIndex((destination) => destination.id === action.payload.id);
      if (index !== -1) {
        state.destinations[index] = action.payload;
      }
    },

    // !* BUNDLES *! //

    getClientBundlesSuccess(state, action) {
      const bundles = get(action.payload, "data.content", []);
      state.clientBundles = bundles;
    },
    getBundlesByIdSuccess(state, action) {
      const bundleId = get(action.payload, "data", []);
      state.bundleid = bundleId;
    },
    getActiveBundlesSuccess(state, action) {
      const bundles = get(action.payload, "data.content", []);
      state.activeBundles = bundles;
    },

    getClientBundleSuccess(state, action) {
      state.clientBundle = action.payload;
    },

    getBundlesSuccess(state, action) {
      state.bundles = action.payload;
    },

    getBundleSuccess(state, action) {
      state.bundle = action.payload;
    },

    addBundleSuccess() {
      // state.bundles.push(action.payload);
    },

    updateBundleSuccess(state, action) {
      const index = state.bundles.findIndex((bundle) => bundle.id === action.payload.id);
      if (index !== -1) {
        state.bundles[index] = action.payload;
      }
    },

    // add new fee
    addProviderList(state, action) {
      state.providers.push(action.payload);
    },

    // add service
    addServiceList(state, action) {
      state.services.push(action.payload);
    },

    // delete service
    deleteServiceList(state, action) {
      state.services = state.services.filter((item) => item.id !== action.payload);
    },

    // delete fee
    deleteProviderList(state, action) {
      state.providers = state.providers.filter((item) => item.id !== action.payload);
    },

    // Activate service
    activateService(state, action) {
      const index = state.services.findIndex((service) => service.id === action.payload);
      if (index !== -1) {
        state.services[index].status = "active";
      }
    },

    // Deactivate service
    deactivateService(state, action) {
      const index = state.services.findIndex((service) => service.id === action.payload);
      if (index !== -1) {
        state.services[index].status = "deactivated";
      }
    },

    // !* SHARE CARE
    shareCashSuccess(state, action) {
      state.shareCash = action.payload;
    },

    // add rate plan
    addRatePlanList(state, action) {
      state.ratePlans.push(action.payload);
    },

    // delete rate plan
    deleteRatePlanList(state, action) {
      state.ratePlans = state.ratePlans.filter((item) => item.id !== action.payload);
    }
  }
});

export default setups.reducer;

export const {
  hasError,
  getBundleSuccess,
  getBundlesSuccess,
  getBalanceSuccess,
  getClientBundleSuccess,
  getClientBundlesSuccess,
  getBundlesByIdSuccess,
  addBundleSuccess,
  updateBundleSuccess,
  getDestinations,
  getDestinationSuccess,
  setInvoicesSuccess,
  setInvoiceSuccess,
  addDestination,
  deleteDestination,
  updateDestination,
  addProviderList,
  createInvoicesSuccess,
  cancelInvoicesSuccess,
  deleteProviderList,
  addServiceList,
  deleteServiceList,
  isLoading,
  activateService,
  deactivateService,
  setInvoiceStatus,
  addRatePlanList,
  deleteRatePlanList,
  getActiveBundlesSuccess,
  shareCashSuccess
} = setups.actions;

export async function billingSettings(token, clientid, data) {
  const response = await axios.put(`clients/${clientid}/billingsettings`, data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
}

/**
 * Retrieves the list of destinations from the server.
 * PERMISSION : ADMIN , STAFF
 * @param {Object} authInfo - The authentication information.
 * @param {string} authInfo.token - The authentication token.
 * @returns {Function} - The async function that dispatches the action.
 */
export const getDestinationList =
  (token, page = 0, size = 50, search = "") =>
  async (dispatch) => {
    try {
      const response = await axios.get("/billing/destinations", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        },
        params: {
          page: page,
          size: size,
          search: search,
          sort: "id,desc"
        }
      });

      dispatch(getDestinations(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };

/**
 * Retrieves the destination information from the server.
 * @param {string} token - The authentication token.
 * @param {string} destinationid - The ID of the destination to retrieve.
 * @returns {Function} - The async function that dispatches the action.
 */
export function getDestination(token, destinationid) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`billing/destinations/${destinationid}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      dispatch(getDestinationSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error.message));
    }
  };
}

/**
 * Creates a destination list.
 * PERMISSION : ADMIN , STAFF
 * @param {string} token - The authorization token.
 * @param {object} destination - The destination object to be added to the list.
 * @returns {Function} - An async function that dispatches actions based on the API response.
 */
export function createDestinationList(token, destination) {
  return async (dispatch) => {
    try {
      const response = await axios.post(`billing/destinations`, destination, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      dispatch(addDestination(response.data));
    } catch (error) {
      dispatch(hasError(error.message));
    }
  };
}

/**
 * Deletes a destination from the server.
 *
 * @param {string} token - The authentication token.
 * @param {string} destinationId - The ID of the destination to delete.
 * @returns {Function} - An async function that dispatches actions based on the result of the delete operation.
 */
export function deleteDestinationList(token, destinationId) {
  return async (dispatch) => {
    try {
      await axios.delete(`billing/destinations/${destinationId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      dispatch(deleteDestination(destinationId));
    } catch (error) {
      dispatch(hasError(error.message));
    }
  };
}

/**
 * Updates the destination list with the provided destination.
 *
 * @param {string} token - The authentication token.
 * @param {string} destinationid - The ID of the destination to update.
 * @param {object} destination - The updated destination object.
 * @returns {Function} - An async function that dispatches the updated destination or error message.
 */
export function updateDestinationList(token, destinationid, destination) {
  return async (dispatch) => {
    try {
      const response = await axios.put(`billing/destinations/${destinationid}`, destination, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      dispatch(updateDestination(response.data));
    } catch (error) {
      dispatch(hasError(error.message));
    }
  };
}

/**
 * Retrieves the balance for a specific client using the provided token.
 * @param {string} token - The authentication token.
 * @param {string} clientid - The ID of the client.
 * @returns {Function} - An async function that dispatches actions based on the API response.
 */
export function getBalance(token, clientid) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`billing/${clientid}/balances`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      dispatch(getBalanceSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error.message));
    }
  };
}

// !* BUNDLES ACTIONS*! //

/**
 * Adds a bundle to the server.
 * @param {string} token - The authorization token.
 * @param {object} bundle - The bundle to be added.
 * @returns {Promise} - A promise that resolves with the response data or rejects with an error.
 */
export function addBundle(token, bundle) {
  return async (dispatch) => {
    try {
      const response = await axios.post("billing/bundles", bundle, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      dispatch(addBundleSuccess(response.data));
    } catch (error) {
      return error;
    }
  };
}

/**
 * Updates a bundle with the given token, bundle ID, and bundle data.
 * @param {string} token - The authorization token.
 * @param {string} bundleid - The ID of the bundle to update.
 * @param {object} bundle - The updated bundle data.
 * @returns {Function} - A Redux thunk function.
 */
export function updateBundle(token, bundleid, bundle) {
  return async (dispatch) => {
    try {
      const response = await axios.put(`billing/bundles/${bundleid}`, bundle, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      dispatch(updateBundleSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error.message));
    }
  };
}

/**
 * Fetches client bundles from the server.
 * PERMISSION :ANY
 * @param {string} token - The authentication token.
 * @param {string} clientid - The client ID.
 * @returns {Function} - The async function that dispatches actions.
 */
export function getClientBundles(token, clientid) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`billing/${clientid}/bundles?sort=id,desc`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: { isactive: false }
      });
      dispatch(getClientBundlesSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error.message));
    }
  };
}

/**
 * Retrieves the active bundles for a given client.
 * @param {string} token - The authentication token.
 * @param {string} clientid - The client ID.
 * @returns {Function} - An async function that dispatches actions based on the API response.
 */
export function getActiveBundles(token, clientid) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`billing/${clientid}/bundles?sort=id,desc`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      dispatch(getActiveBundlesSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error.message));
    }
  };
}

/**
 * Fetches bundles by id from the server.
 * PERMISSION :ANY
 * @param {string} token - The authentication token.
 * @param {string} clientid - The client ID.
 * @param {string} bundleid - The bundle ID.
 * @returns {Function} - The async function that dispatches actions.
 */
export function getBundlesById(token, clientid, bundleid) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`billing/${clientid}/bundles/${bundleid}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      dispatch(getBundlesByIdSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error.message));
    }
  };
}

/**
 * Retrieves the client bundle from the server.
 * PERMISSION :ANY
 * @param {string} token - The authentication token.
 * @param {string} clientid - The client ID.
 * @param {string} bundleid - The bundle ID.
 * @returns {Function} - The async function that dispatches actions.
 */
export function getClientBundle(token, clientid, bundleid) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`billing/${clientid}/bundles/${bundleid}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      dispatch(getClientBundleSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error.message));
    }
  };
}

/**
 * PERMISSION : ADMIN , STAFF
 * Fetches bundles from the server using the provided token.
 * @param {string} token - The authentication token.
 * @returns {Function} - A function that dispatches actions based on the API response.
 */
export function getBundles(token, page = 0, size = 1000, search = "") {
  return async (dispatch) => {
    try {
      const response = await axios.get(`billing/bundles?sort=id,desc&page=${page}&size=${size}&search=${search}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      dispatch(getBundlesSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error.message));
    }
  };
}

/**
 * PERMISSION : ADMIN , STAFF
 * Retrieves a bundle using the provided token and bundle ID.
 * @param {string} token - The authorization token.
 * @param {string} bundleid - The ID of the bundle to retrieve.
 * @returns {Function} - An async function that dispatches actions based on the API response.
 */
export function getBundle(token, bundleid) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`billing/bundles/${bundleid}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      dispatch(getBundleSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error.message));
    }
  };
}

// !* PAYMENTS

/**
 * Initiates a payment for a given invoice using the specified payment mode.
 * @param {string} token - The authentication token.
 * @param {string} invoiceid - The ID of the invoice.
 * @param {string} paymentmode - The payment mode to be used.
 * @returns {Promise<any>} - A promise that resolves to the response data.
 */
export async function initiatePayment(token, invoiceid, paymentmode) {
  const response = await axios.put(`billing/invoices/${invoiceid}/initpayment`, null, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    params: {
      paymentmode: paymentmode
    }
  });
  return response.data;
}

/**
 * Shares cash with a client.
 * @param {string} token - The authorization token.
 * @param {string} clientid - The client ID.
 * @param {object} shareData - The data to be shared.
 * @returns {Function} - An async function that dispatches actions based on the API response.
 */
export async function shareCash(token, clientid, shareData) {
  const response = await axios.post(`billing/${clientid}/sharecash`, shareData, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
}

// !* INVOICES *! //

/**
 * Checks the status of an invoice.
 * @param {string} token - The authorization token.
 * @param {string} invoiceid - The ID of the invoice to check.
 * @returns {Function} - An async function that dispatches the invoice status.
 */
export function checkInvoiceStatus(token, invoiceid) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`billing/invoices/${invoiceid}/status`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      dispatch(setInvoiceStatus(response.data));
    } catch (error) {
      console.log(error);
    }
  };
}

/**
 * Sets the invoice as paid.
 * @param {string} token - The authorization token.
 * @param {string} invoiceid - The ID of the invoice to set as paid.
 * @returns {Promise<any>} - A promise that resolves to the response data.
 */
export async function setInvoicePaid(token, invoiceid) {
  const response = await axios.put(`billing/invoices/${invoiceid}/setpaid`, null, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
}

/**
 * Retrieves the list of invoices for a specific client.
 * PERMISSION :ANY
 * @param {Object} data - The data object containing the token and client ID.
 * @param {string} data.token - The authentication token.
 * @param {string} data.clientid - The ID of the client.
 * @returns {Function} - An async function that dispatches actions based on the API response.
 */
export const getInvoiceList =
  (token, clientid, page = 0, pageSize = 50, search = "") =>
  async (dispatch) => {
    try {
      const response = await axios.get(`/billing/${clientid}/invoices?page=${page}&size=${pageSize}&sort=id%2Cdesc&search=${search}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      });

      dispatch(setInvoicesSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };

/**
 * PERMISION :ANY
 * Retrieves an invoice from the server.
 * @param {string} token - The authentication token.
 * @param {string} clientid - The client ID.
 * @param {string} invoiceid - The invoice ID.
 * @returns {Function} - An async function that dispatches actions.
 */
export const getInvoice = (token, clientid, invoiceid) => async (dispatch) => {
  try {
    const response = await axios.get(`/billing/${clientid}/invoices/${invoiceid}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`
      }
    });

    dispatch(setInvoiceSuccess(response.data));
  } catch (error) {
    dispatch(hasError(error));
  }
};

/**
 * Creates an invoice list for a client.
 * PERMISSION :ANY
 * @param {Object} data - The data object containing the token and client ID.
 * @param {Object} invoice - The invoice object to be created.
 * @param {Object}
 * {
  "amount": 0,
  "benefactorid": 0,
  "bundleid": 0,
  "description": "string",
  "invoicetype": "BUNDLE"
    }
 * @param {Function} dispatch - The dispatch function from Redux.
 * @returns {Promise<void>} - A promise that resolves when the invoice list is created.
 */

export async function createInvoiceList(token, clientid, invoice) {
  const response = await axios.post(`/billing/${clientid}/invoices`, invoice, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    }
  });

  return response.data;
}
/**
 * Cancels an invoice list for a client.
 * PERMISSION :ANY
 * @param {Object} invoiceid - The invoiceid to be canceled.
 * @param {Object}

 * @param {Function} dispatch - The dispatch function from Redux.
 * @returns {Promise<void>} - A promise that resolves when the invoice list is created.
 */
export async function cancelInvoiceList(token, invoiceid) {
  const response = await axios.put(`/billing/invoices/${invoiceid}/cancel`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`
    }
  });

  return response.data;
}
