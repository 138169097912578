import { useEffect, useMemo, useState } from "react";

import { get } from "lodash";
import { getClientByIdNoState } from "store/reducers/users";
import useAuth from "./useAuth";

const useUserAuthInfo = (getClientName = false) => {
  const { user } = useAuth();
  const [clientData, setClientData] = useState(null);

  const clientid = useMemo(() => get(user, "data[0].useraccount.clientid"), [user]);
  const name = useMemo(() => get(user, "data[0].useraccount.name"), [user]);
  const token = useMemo(() => get(user, "data[0].accessToken.token"), [user]);
  const userType = useMemo(() => get(user, "data[0].useraccount.usertype"), [user]);
  const userid = useMemo(() => get(user, "data[0].useraccount.id"), [user]);
  const parentid = useMemo(() => get(user, "data[0].accessToken.id"), [user]);

  useEffect(() => {
    if (getClientName && token && clientid && !["ADMIN", "STAFF"].includes(userType)) {
      getClientByIdNoState(token, clientid)
        .then((res) => {
          setClientData(res.data);
        })
        .catch(() => {
          setClientData(null);
        });
    }
  }, [token, clientid]);

  return { user, clientid, token, userType, userid, parentid, name, clientName: clientData?.name };
};

export default useUserAuthInfo;
