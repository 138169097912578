import { Box, Container, Fab, Toolbar, useMediaQuery } from "@mui/material";

// import { AddCircle } from "iconsax-react";
import Breadcrumbs from "components/@extended/Breadcrumbs";
import { DRAWER_WIDTH } from "config";
// project-imports
import Drawer from "./Drawer";
import Footer from "./Footer";
import Header from "./Header";
import HorizontalBar from "./Drawer/HorizontalBar";
import { MenuOrientation } from "config";
// import NavigationIcon from "@mui/icons-material/Navigation";
import { Outlet } from "react-router-dom";
import { Send } from "iconsax-react";
// import navigation from "menu-items";
import allMenuItems from "menu-items/all-menu-items";
// import cover from "assets/images/circuit-board.svg";
import { dispatch } from "store";
import { openDrawer } from "store/reducers/menu";
import useConfig from "hooks/useConfig";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
// material-ui
import { useTheme } from "@mui/material/styles";

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const downXL = useMediaQuery(theme.breakpoints.down("xl"));
  const downLG = useMediaQuery(theme.breakpoints.down("lg"));

  const location = useLocation();

  // Function to check if the current route matches the specified route
  const isRoute = (route) => {
    return location.pathname === route;
  };
  const additionalRoutes = ["/address-book/bulk-imports", "/bulk-messaging/schedule-a-message/schedule"];

  const { container, miniDrawer, menuOrientation } = useConfig();

  const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !downLG;

  // set media wise responsive drawer
  useEffect(() => {
    if (!miniDrawer) {
      dispatch(openDrawer(!downXL));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downXL]);

  return (
    <Box
      sx={{
        display: "flex",
        bgcolor: "#ECF1F3",
        width: "100%"
      }}
    >
      <Header />
      {!isHorizontal ? <Drawer /> : <HorizontalBar />}

      <Box
        component="main"
        sx={{
          width: `calc(100% - ${DRAWER_WIDTH}px)`,
          flexGrow: 1,
          p: { xs: 2, md: 3 }
        }}
      >
        <Toolbar sx={{ mt: isHorizontal ? 8 : "inherit", mb: isHorizontal ? 2 : "inherit" }} />
        <Container
          maxWidth={container ? "xl" : false}
          sx={{
            xs: 0,
            ...(container && { px: { xs: 0, md: 2 } }),
            position: "relative",
            minHeight: "calc(100vh - 110px)",
            display: "flex",
            flexDirection: "column"
          }}
        >
          <Breadcrumbs navigation={allMenuItems} title titleBottom card={false} divider={false} />
          <Outlet />
          {/* hide if route is ""/bulk-messaging/send-message"" */}

          {/* <Fab
            LinkComponent={"a"}
            href={"/bulk-messaging/send-message"}
            variant="extended"
            color="success"
            sx={{
              position: "fixed",
              right: theme.spacing(4),
              bottom: theme.spacing(4)
            }}
          >
            Send Message
          </Fab> */}

          {!isRoute("/bulk-messaging/send-message") && !additionalRoutes.some((route) => isRoute(route)) && (
            <Fab
              LinkComponent={"a"}
              href={"/bulk-messaging/send-message"}
              variant="extended"
              color="success"
              sx={{
                position: "fixed",
                right: theme.spacing(4),
                bottom: theme.spacing(4)
              }}
            >
              Send Message
              <Send sx={{ ml: 5 }} />
            </Fab>
          )}
          <Footer />
        </Container>
      </Box>
    </Box>
  );
};

export default MainLayout;
