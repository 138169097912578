// import { get, omit } from "lodash";

import axios from "utils/axios";
import { createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";

export const initialState = {
  error: null,
  allUsers: [],
  userById: null,
  clients: [],
  clientsById: null,
  clientUsers: [],
  clientSubClients: [],
  selectedSublientClients: [],
  selectedSubclientUsers: [],
  refferalstats: [],
  billingresponsibilities: [],
  newSubClientDetails: {},
  clientStatistics: []
};

const usersmanagement = createSlice({
  name: "usersmanagement",
  initialState,
  reducers: {
    // has error
    hasError: (state, action) => {
      state.error = action.payload;
    },

    getClientStatisticsSuccess: (state, action) => {
      state.clientStatistics = action.payload;
    },

    setNewSubClientDetails: (state, action) => {
      console.log("🚀 ~ setNewSubClientDetails ~ action.payload", action.payload);
      state.newSubClientDetails = { ...state.newSubClientDetails, ...action.payload };
    },

    getRefferalStatsSuccess: (state, action) => {
      state.refferalstats = action.payload;
    },

    getBillingResponsibilitiesSuccess: (state, action) => {
      const billings = get(action, "payload.data");
      state.billingresponsibilities = billings;
    },

    getAllUsersSuccess: (state, action) => {
      const user = get(action, "payload.data");
      state.allUsers = user;
    },

    getUserByIdSuccess: (state, action) => {
      const user = get(action, "payload.data");
      state.userById = user;
    },

    getClientsSuccess: (state, action) => {
      const clients = get(action, "payload.data");
      state.clients = clients;
    },

    getClientByIdSuccess: (state, action) => {
      const client = get(action, "payload.data");
      // console.log("🚀 ~ client:", client);
      state.clientsById = client;
    },

    getClientUsersSuccess: (state, action) => {
      const users = get(action, "payload.data");
      state.clientUsers = users;
    },

    getClientSubClientsSuccess: (state, action) => {
      const subclients = get(action, "payload.data");
      state.clientSubClients = subclients;
    },

    addUserSuccess: (state, action) => {
      state.allUsers = action.payload;
    },

    addClientUserSuccess: (state, action) => {
      state.clientUsers = action.payload;
    },

    addClientSubClientSuccess: (state, action) => {
      state.clientSubClients = action.payload;
    },

    // filter to get selected sublients, sublients from "clientSubClients" state using parentid

    getSelectedSubClients: (state, action) => {
      const parentid = action.payload;
      state.selectedSublientClients = _.filter(state.clientSubClients.content, { parentid: parentid });
    },

    getSelectedSubclientUsers: (state, action) => {
      const parentid = action.payload;
      state.selectedSubclientUsers = _.filter(state.clientUsers, { clientid: parentid });
    },

    editBillingResponsibilitiesSuccess: (state, action) => {
      const billings = get(action, "payload.data");
      state.billingresponsibilities = billings;

      // const { clientid, billingresponsibilities } = action.payload;
      // const index = state.billingresponsibilities.findIndex((item) => item.clientid === clientid);
      // if (index !== -1) {
      //   state.billingresponsibilities[index] = billingresponsibilities;
      // } else {
      //   state.billingresponsibilities = [...state.billingresponsibilities, billingresponsibilities];
      // }
    },

    adminEditUserSuccess: (state, action) => {
      const { userid, user } = action.payload;
      const index = state.allUsers.findIndex((item) => item.userid === userid);
      if (index !== -1) {
        state.allUsers[index] = user;
      } else {
        state.allUsers = [...state.allUsers, user];
      }
    },

    editUserSuccess: (state, action) => {
      const { userid, user } = action.payload;
      const index = state.allUsers.findIndex((item) => item.userid === userid);
      if (index !== -1) {
        state.allUsers[index] = user;
      } else {
        state.allUsers = [...state.allUsers, user];
      }
    },

    editClientSuccess: (state, action) => {
      const { clientid, client } = action.payload;
      const index = state.clients.findIndex((item) => item.clientid === clientid);
      if (index !== -1) {
        state.clients[index] = client;
      } else {
        state.clients = [...state.clients, client];
      }
    }
  }
});

export default usersmanagement.reducer;

export const {
  hasError,
  getRefferalStatsSuccess,
  getBillingResponsibilitiesSuccess,
  getAllUsersSuccess,
  getUserByIdSuccess,
  getClientsSuccess,
  getClientByIdSuccess,
  getClientUsersSuccess,
  getSelectedSubClients,
  getSelectedSubclientUsers,
  getClientSubClientsSuccess,
  editBillingResponsibilitiesSuccess,
  addUserSuccess,
  addClientUserSuccess,
  addClientSubClientSuccess,
  adminEditUserSuccess,
  editUserSuccess,
  editClientSuccess,
  setNewSubClientDetails,
  getClientStatisticsSuccess
} = usersmanagement.actions;

// !* GET ACTIONS

/**
 * Retrieves client statistics using the provided token and client ID.
 * @param {string} token - The authentication token.
 * @param {string} clientid - The ID of the client.
 * @returns {Function} - An async function that dispatches actions based on the API response.
 */
export function getClientStatistics(token, clientid) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/clients/${clientid}/statistics`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      });
      dispatch(getClientStatisticsSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

/**
 * Retrieves referral statistics for a client.
 * @param {Object} data - The data object containing the token and client ID.
 * @param {string} data.token - The authentication token.
 * @param {string} data.clientid - The client ID.
 * @returns {Function} - The async function that dispatches actions based on the API response.
 */
export function getRefferalStats(token, clientid) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/clients/${clientid}/referralstats`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      });
      dispatch(getRefferalStatsSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

/**
 * Retrieves billing responsibilities for a client.
 * @param {Object} data - The data object containing the token and clientid.
 * @param {string} data.token - The authentication token.
 * @param {string} data.clientid - The client ID.
 * @returns {Function} - An async function that dispatches actions based on the API response.
 */
export function getBillingResponsibilities(token, clientid) {
  // console.log("🚀 ~ getBillingResponsibilities ~ token:", token);
  return async (dispatch) => {
    try {
      const response = await axios.get(`/clients/${clientid}/billingresponsiblity`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      });
      dispatch(getBillingResponsibilitiesSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

/**
 * Edits the billing responsibilities for a client.
 * @param {Object} data - The data object containing the necessary information.
 * @param {string} data.token - The authentication token.
 * @param {string} data.clientid - The ID of the client.
 * @param {Object} data.billingresponsibilities - The updated billing responsibilities.
 * @returns {Function} - An async function that dispatches actions based on the API response.
 */
export async function editBillingResponsibilities(data) {
  const { token, clientid, billingresponsibilities } = data;

  const response = await axios.put(`/clients/${clientid}/billingresponsiblity`, billingresponsibilities, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
}

export async function blockSubclient(token, clientid) {
  const response = await axios.put(`/auth/blockclient/${clientid}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
}

/**
 * Unblocks a subclient.
 * @param {string} token - The authentication token.
 * @param {string} clientid - The ID of the client to unblock.
 * @returns {Promise<any>} - A promise that resolves to the response data.
 */
export async function unBlockSubclient(token, clientid) {
  const response = await axios.put(`/auth/unblockclient/${clientid}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
}

export async function enableUserLogin(token, userid) {
  const response = await axios.put(`/auth/enablelogin/${userid}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
}

export async function disableUserLogin(token, userid) {
  const response = await axios.put(`/auth/disablelogin/${userid}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
}

/**
 * Retrieves all users based on the provided data.
 * Permission:STAFF,ADMIN
 * @param {Object} data - The data object containing the token and usertype.
 * @returns {Function} - The async function that dispatches the action.
 */
export function getAllUsers(token, usertype, page = 0, size = 100, search = "") {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/clients/allusers`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        params: {
          usertype: usertype,
          page,
          size,
          search,
          sort: "name,asc"
        }
      });
      dispatch(getAllUsersSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

/**
 * Retrieves user information by user ID.
 * Permission:ANY
 * @param {Object} data - The data object containing the token and user ID.
 * @param {string} data.token - The authentication token.
 * @param {string} data.userid - The user ID.
 * @returns {Function} - The async function that dispatches actions based on the API response.
 */
export function getUserById(token, userid) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/clients/user/${userid}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      });

      dispatch(getUserByIdSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

/**
 * Retrieves the clients using the provided token.
 * Permission:STAFF,ADMIN
 * @param {string} token - The authentication token.
 * @returns {Function} - The async function that dispatches the getClientsSuccess action or hasError action.
 */
export function getClients(token, page = 0, size = 50, search = "") {
  return async (dispatch) => {
    try {
      const response = await axios.get("/clients", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        params: {
          page,
          size,
          search,
          sort: "name,asc"
        }
      });
      dispatch(getClientsSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

/**
 * Retrieves a client by ID.
 * Permission:ANY
 * @param {Object} data - The data object containing the token and client ID.
 * @param {string} data.token - The authentication token.
 * @param {string} data.clientid - The ID of the client to retrieve.
 * @returns {Function} - The async function that dispatches actions based on the API response.
 */
export function getClientById(token, clientid) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/clients/${clientid}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      });
      dispatch(getClientByIdSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export async function getClientByIdNoState(token, clientid) {
  const response = await axios.get(`/clients/${clientid}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
}

/**
 * Retrieves the client users from the server.
 * Permission:ANY
 * @param {Object} data - The data object containing the token and client ID.
 * @returns {Function} - The async function that dispatches the action.
 */
export function getClientUsers(token, clientid) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/clients/${clientid}/users`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      });

      dispatch(getClientUsersSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

/**
 * Retrieves the sub-clients for a given client from the server.
 * @param {Object} data - The data object containing the token and client ID.
 * @param {string} data.token - The authentication token.
 * @param {string} data.clientid - The ID of the client.
 * @returns {Function} - The async function that dispatches actions based on the server response.
 */
export function getClientSubClients(token, clientid) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/clients/${clientid}/subclients`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        params: {
          recursive: true
        }
      });
      dispatch(getClientSubClientsSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// !* POST ACTIONS

/**
 * Adds a user to the server.
 * Permission:ADMIN
 * @param {Object} data - The data containing the token and user information.
 * @param {string} data.token - The authentication token.
 * @param {Object} data.user - The user information to be added.
 * @returns {Function} - The async function that dispatches actions based on the server response.
 */
export function addUser(data) {
  const { token, user } = data;
  return async (dispatch) => {
    try {
      const response = await axios.post("/clients/adduser", user, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      });
      dispatch(addUserSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

/**
 * Adds a client user.
 * Permission:ANY
 * @param {Object} data - The data object containing the token, clientid, and user information.
 * @returns {Function} - The async function that dispatches actions based on the response.
 */
export async function addClientUser(token, clientid, newUser) {
  const response = await axios.post(`/clients/${clientid}/users`, newUser, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return response.data;
}

/**
 * Adds a subclient to a client.
 * Permission:ANY
 * @param {Object} data - The data object containing the token, clientid, and subclient.
 * @param {string} data.token - The authentication token.
 * @param {string} data.clientid - The ID of the client.
 * @param {Object} data.subclient - The subclient object to be added.
 * @returns {void} - The async function that dispatches the action.
 */
export async function addClientSubClient(token, clientid, subclient) {
  const response = await axios.post(`/clients/${clientid}/subclients`, subclient, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
}

/**
 * Edits a user as an admin.
 * Permission:ADMIN
 * @param {Object} data - The data required for editing the user.
 * @param {string} data.token - The authentication token.
 * @param {string} data.clientid - The client ID.
 * @param {Object} data.user - The user object containing the updated user data.
 * @returns {Function} - The async function that dispatches the action.
 */
export function adminEditUser(token, clientid, user) {
  return async (dispatch) => {
    try {
      const response = await axios.post(`/clients/adminedit${clientid}`, user, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      });
      dispatch(adminEditUserSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

/**
 * Edits a user's data.
 * Permission:ANY
 * @param {Object} data - The data required for editing the user.
 * @param {string} data.token - The authentication token.
 * @param {string} data.userid - The user ID.
 * @param {Object} data.user - The updated user data.
 * @returns {Function} - An async function that dispatches actions based on the API response.
 */
export async function editUser(data) {
  const { token, userid, user } = data;

  const response = await axios.put(`/clients/edituser/${userid}`, user, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
}

/**
 * Edits a client's data.
 *Permission:ANY
 * @param {Object} data - The data required for editing the client.
 * @param {string} data.token - The authentication token.
 * @param {string} data.clientid - The ID of the client to be edited.
 * @param {Object} data.client - The updated client data.
 * @returns {Function} - An async function that dispatches actions based on the API response.
 */
export async function editClient(data) {
  const { token, clientid, client } = data;
  const response = await axios.put(`/clients/${clientid}`, client, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
}

export async function getClientByIdWithNoState(data) {
  const { token, clientid } = data;
  const response = await axios.get(`/clients/${clientid}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
}

export async function sendApiDetails(token, userid) {
  const response = await axios.get(`/auth/sendapidetails/${userid}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  });
  return response.data;
}
